import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import rem from '../../style/rem';
import RightArrow from '../RightArrow';

const A = styled.a`
  text-decoration: none;
`;

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: ${props => (
    (props.theme.projectColors[props.colorKey]
      && props.theme.projectColors[props.colorKey].main)
    || props.theme.backgroundColor
  )};
  background: ${props => (
    (props.theme.projectColors[props.colorKey]
      && props.theme.projectColors[props.colorKey].glow)
    || props.theme.backgroundColor
  )};
  margin-bottom: ${rem(16)};
  padding-top: ${rem(24)};
  border-radius: 2px;
  box-shadow: 0 0 18px 0 rgba(0, 5, 8, .12), 1px 4px 8px 0 rgba(0, 5, 8, .2);
`;

const P = styled.p`
  margin-bottom: ${rem(16)};
`;

const MockA = styled.p`
  color: ${props => props.theme.linkColor};
  fill: ${props => props.theme.linkColor};
  font-size: ${props => props.theme.fontSizeP};
  line-height: ${rem(24)};
  font-weight: 700;
  text-decoration: none;
  display: inline-flex;
  padding-bottom: 8px;
  position: relative;

  :hover .link-anim {
    margin-left: 16px;
  }
`;

const LinkAnim = styled.span`
  display: inline-flex;
  width: ${rem(14)};
  transition: margin-left 0.2s ease;
  margin-left: 10px;

  svg {
    width: 100%;
  }
`;

const { string, number } = PropTypes;
const propTypes = {
  description: string.isRequired,
  imageUrl: string.isRequired,
  imageAlt: string.isRequired,
  link: string.isRequired,
  linkText: string.isRequired,
  colorKey: string.isRequired,
  projectCount: number.isRequired,
};

const ProjectLink = ({
  description,
  imageUrl,
  imageAlt,
  link,
  linkText,
  colorKey,
  projectCount,
}) => (
  <A href={link} projectCount={projectCount}>
    <ImageContainer colorKey={colorKey}>
      <img src={imageUrl} alt={imageAlt} />
    </ImageContainer>
    <P>{description}</P>
    <MockA>
      {linkText}
      <LinkAnim className="link-anim">
        <RightArrow />
      </LinkAnim>
    </MockA>
  </A>
);

ProjectLink.propTypes = propTypes;

export default ProjectLink;
