import { createGlobalStyle } from 'styled-components';
import rem from './rem';

const GlobalStyle = createGlobalStyle`
  body {
    font-size: ${props => props.theme.fontSizeP};
    background-color: ${props => props.theme.backgroundColor};
    line-height: ${rem(26)};
    letter-spacing: 0.03125rem;
  }

  h1, h2, h3 {
    font-weight: 600;
  }

  h1 {
    font-size: ${props => props.theme.fontSizeH1};
    line-height: ${rem(64)};
    margin-bottom: ${rem(56)};
  }
  h2 {
    font-size: ${props => props.theme.fontSizeH2};
    line-height: ${rem(40)};
    padding-top: ${rem(48)};
    margin-bottom: ${rem(120)};
  }
  h3 {
    font-size: ${props => props.theme.fontSizeH3};
    line-height: ${rem(32)};
    margin-bottom: ${rem(24)};
  }
  p {
    font-size: ${props => props.theme.fontSizeP};
    color: ${props => props.theme.fontColorMedium};
    margin-bottom: ${rem(24)};
  }

  a {
    color: ${props => props.theme.linkColor};
  }

  a:visited {
    color: ${props => props.theme.visitedLinkColor}
  }

  a.fa-icon svg {
    color: ${props => props.theme.colorPrimary};
    font-size: ${rem(28)};
    margin: 10px;
  }

  em {
    font-style: italic;
  }

  strong {
    font-weight: 700;
  }

  li {
    color: ${props => props.theme.fontColorMedium};
  }

  blockquote {
    font-size: ${rem(18)};
    background: #f9f9f9;
    border-left: 16px solid ${props => props.theme.fontColorTransparent};
    margin: ${rem(64)} ${rem(24)};
    padding: ${rem(24)} ${rem(24)};
  }

  .grid {
    display: grid;
    grid-gap: 32px;
  }

  .col-1-1 {
    grid-template-columns: repeat(2, 1fr);
  }

  .col-2-1 {
    grid-template-columns: 2fr 1fr;
  }

  .col-1-2 {
    grid-template-columns: 1fr 2fr;
  }

  .col-1-1-1 {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 56px;
  }

  .col-1-5 {
    grid-template-columns: 1fr 5fr;
    grid-gap: 0;
  }

  .grid-item-center {
    align-self: center;
    justify-self: center;
  }

  .justify {
    text-align: justify;
  }

  .captionLarge {
    font-size: ${props => props.theme.fontSizePSmall};
    font-style: italic;
  }

  .storyHighlight {
    border-left: ${rem(16)} solid #E2E2E4;
    padding-left: ${rem(32)};
  }

  .about-portrait {
    width: ${rem(320)};
    height: ${rem(320)};
    display: block;
    margin: ${rem(48)} auto ${rem(32)} auto;
  }

  .centeredContent {
    text-align: center;
  }

  .portfolio-project-page {
    margin-top: ${rem(64)};
  }

  .portfolio-project-page img {
    display: block;
    margin: ${rem(72)} auto;
  }

  img.figureSmall {
    margin-top: 0;
    width: ${rem(500)};
  }

  img.showcase {
    max-width: 150%;
    width: 150%;
    margin-left: -25%;
  }

  img.noSpacing {
    margin: 0;
  }

  .portfolio-project-page h1 {
    text-align: center;
  }

  .subtitle {
    color: ${props => props.theme.fontColorTransparent};
    font-size: ${rem(24)};
    font-weight: 400;
    line-height: ${rem(24)};
    text-align: center;
    margin-bottom: ${rem(32)};
  }

  .portfolio-body {
    margin-top: ${rem(64)};
    margin-bottom: ${rem(184)};
  }

  .portfolio-body ul,
  .portfolio-body ol {
    list-style-position: outside;
    padding-left: ${rem(48)};
    margin-bottom: ${rem(24)};
  }

  .portfolio-body li {
    margin-bottom: ${rem(8)};
  }

  .portfolio-body ul {
    list-style-type: disc;
  }

  .portfolio-body ol {
    list-style-type: decimal;
  }

  .shadow {
    filter: drop-shadow(0 0 10px rgba(0, 0, 20, 0.1));
  }

  .portfolio-body figure {
    margin-bottom: ${rem(72)};
  }

  .portfolio-body figcaption {
    font-size: ${props => props.theme.fontSizePSmall};
    color: ${props => props.theme.fontColorTransparent};
    line-height: ${rem(22)};
    text-align: center;
    margin-top: ${rem(-56)};
  }

  .portfolio-section {
    margin-bottom: ${rem(120)};
  }
  .portfolio-subsection {
    margin-bottom: ${rem(96)};
  }

  .label span {
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 1px;
    background-color: #E2E2E4;
    border-radius: 2px;
    padding: 8px;
  }

  .insight {
    background-color: #F6F6F8;
    padding: 16px;
    border-radius: 2px;
  }

  .fullWidthQuote {
    background-color: #F6F6F8;
    padding: 40px;
    border-radius: 2px;
    font-style: italic;
  }

  .quoteSignature {
    font-style: normal;
  }

  .footer {
    margin: 0 auto;
  }

  .caption-footer {
    font-size: ${props => props.theme.fontSizePSmall};
    margin-bottom: ${rem(8)};
  }

  .fade-in-up {
    animation: fadeInUp 0.5s;
  }
  ${[...Array(10).keys()].map(index => (
    `.fade-in-up-staggered > :nth-child(${index + 1}) {
       animation: fadeInUp ${index * 0.5 + 1}s;
     }`
  ))}

  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translate3d(0, 40px, 0);
    }

    to {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }

  @media only screen and (min-width: 768px) {
    .portfolio-body {
      padding: 0 ${rem(128)};
    }
  }

  @media only screen and (max-width: 768px) {
    h1 {
      font-size: ${props => props.theme.fontSizeH1Small};
      line-height: ${rem(48)};
    }
    h2 {
      font-size: ${props => props.theme.fontSizeH2Small}
      line-height: ${rem(36)};
    }
    h3 {
      font-size: ${props => props.theme.fontSizeH3Small}
      line-height: ${rem(28)};
    }

    .col-1-1,
    .col-2-1,
    .col-1-2,
    .col-1-5,
    .col-1-1-1 {
      grid-template-columns: 1fr;
    }
  }

  @media only screen and (max-width: 1110px) {
    img.showcase {
      max-width: 100%;
      width: 100%;
      margin-left: 0;
    }
  }
`;

export default GlobalStyle;
