import rem from '../rem';

const fontFamily = "'Muli', sans-serif";

const colorPrimary = '#2AA5AA';
const visitedLinkColor = '#196366';
const footerBackgroundColor = '#FFFFFF';

const accentColor = '#65C1C9';
const accentColorSecondary = '#E6FDFF';
const accentColorHighlight = '#FDB929';

const fontColor = 'rgba(35, 40, 45, 0.85)';
const fontColorMedium = 'rgba(35, 40, 45, 0.78)';
const fontColorTransparent = 'rgba(35, 40, 45, 0.5)';
const fontColorWhite = 'rgba(255, 255, 255, 0.95)';
const fontColorWhiteTransparent = 'rgba(255, 255, 255, 0.8)';

const maxWidth = '1080px';
const gridSpaceBetween = '48px';

// Project colors
const urbanLeap1Color = '#303E4E';
const urbanLeap1Glow = 'radial-gradient(circle, rgba(64,80,99,1) 0%, rgba(48,62,78,1) 65%)';

const urbanLeap2Color = '#008F9C';
const urbanLeap2Glow = 'radial-gradient(circle, rgba(75,178,187,1) 0%, rgba(0,143,156,1) 65%)';

const urbanLeap3Color = '#19988C';
const urbanLeap3Glow = 'radial-gradient(circle, rgba(39,170,157,1) 0%, rgba(25,152,140,1) 65%)';

const greyWireColor = '#3F9AC5';
const greyWireGlow = 'radial-gradient(circle, rgba(84,167,207,1) 0%, rgba(63,154,197,1) 65%)';

const futureAdvisorColor = '#396BAB';
const futureAdvisorGlow = 'radial-gradient(circle, rgba(78,123,180,1) 0%, rgba(57,107,171,1) 65%)';

const commonTheme = {
  fontFamily,

  fontSizeH1: rem(56),
  fontSizeH2: rem(32),
  fontSizeH3: rem(22),
  fontSizeP: rem(17),

  fontSizeH1Small: rem(40), // lh 48
  fontSizeH2Small: rem(28),
  fontSizeH3Small: rem(20),
  fontSizePSmall: rem(14),

  visitedLinkColor,

  footerBackgroundColor,

  fontColorWhite,
  colorPrimary,
  accentColor,
  accentColorSecondary,
  accentColorHighlight,

  menuColor: fontColorTransparent,
  activeMenuColor: fontColor,

  maxWidth,
  gridSpaceBetween,

  projectColors: {
    urbanLeap1: {
      main: urbanLeap1Color,
      glow: urbanLeap1Glow,
    },
    urbanLeap2: {
      main: urbanLeap2Color,
      glow: urbanLeap2Glow,
    },
    urbanLeap3: {
      main: urbanLeap3Color,
      glow: urbanLeap3Glow,
    },
    greyWire: {
      main: greyWireColor,
      glow: greyWireGlow,
    },
    futureAdvisor: {
      main: futureAdvisorColor,
      glow: futureAdvisorGlow,
    },
  },
};

export const darkTheme = {
  ...commonTheme,

  fontColor: fontColorWhite,
  fontColorTransparent: fontColorWhiteTransparent,
  linkColor: fontColorWhite,
  backgroundColor: colorPrimary,
};

export const lightTheme = {
  ...commonTheme,

  fontColor,
  fontColorMedium,
  fontColorTransparent,
  linkColor: colorPrimary,
  backgroundColor: '#fff',
};
