import React from 'react';

/* eslint-disable react/jsx-one-expression-per-line */

const UrbanLeapOverview = () => (
  <section className="portfolio-project-page fade-in-up-staggered">
    <h2 className="subtitle">UrbanLeap</h2>
    <h1>Reflecting on a 0 → 1 journey</h1>
    <img
      src="/images/projects/urbanleapOverview/urbanleap_overview_splash.png"
      alt=""
    />
    <section className="portfolio-body">
      <section className="portfolio-section">
        <p className="justify">
          I joined UrbanLeap in late 2017 as their founding designer and first
          employee. I helped them grow to $3.3M in seed funding, 20+ paying
          customers (cities like Las Vegas and Tel Aviv), and a team of 10. This
          is a short reflection on my journey as the only designer and a sample
          of the type of projects I worked on during my two year tenure.
        </p>
        <img
          className="showcase"
          src="/images/projects/urbanleapOverview/urbanleap_overview_skyline.svg"
          alt="..."
        />
      </section>

      <section className="portfolio-section">
        <section className="portfolio-subsection">
          <h2>Overview</h2>
          <div className="grid col-2-1">
            <div>
              <p className="label"><span>The Company</span></p>
              <h3>UrbanLeap: An innovation-project management platform for local government</h3>
              <p className="justify">
                UrbanLeap is an early-stage startup with a web application for
                local governments to manage innovation projects and programs.
                Their ultimate goal is an operational marketplace where
                municipal staff members can network with one another to leverage
                learnings and public data from other municipalities.
              </p>
            </div>
            <div className="grid-item-center">
              <img
                src="/images/projects/urbanleap2/urbanleap_wordmark.png"
                alt="UrbanLeap Wordmark"
              />
            </div>
          </div>
        </section>

        <section className="portfolio-subsection">
          <div className="grid col-1-1">
            <div>
              <img
                className="noSpacing"
                src="/images/projects/urbanleapOverview/urbanleap_overview_elgl-group.jpg"
                alt=""
              />
            </div>
            <div>
              <p className="label"><span>The Users</span></p>
              <h3>Innovation project managers & <br />Innovation overseers</h3>
              <p>
                Local government department heads or CIOs are typically the
                customers, while project managers are the primary users. They
                come from departments like Transportation, IT, Police, and Fire,
                and their responsibilities range from planning to execution.
              </p>
            </div>
          </div>
        </section>

        <section className="portfolio-subsection">
          <div className="grid col-1-1">
            <div>
              <img
                className="noSpacing"
                src="/images/projects/urbanleapOverview/urbanleap_overview_me.jpg"
                alt=""
              />
            </div>
            <div>
              <p className="label"><span>My Role</span></p>
              <h3>Founding designer</h3>
              <p>
                I was the founding and only designer, responsible for
                collaborating with the CTO on product while owning design from
                generative user research through final hi-fidelity prototypes.
              </p>
            </div>
          </div>
        </section>

        <section className="portfolio-subsection">
          <div className="grid col-1-1">
            <div>
              <img
                className="noSpacing"
                src="/images/projects/urbanleapOverview/urbanleap_overview_team.jpg"
                alt=""
              />
            </div>
            <div>
              <p className="label"><span>The Team</span></p>
              <h3>Engineers, Customer Success, Sales, Biz-dev, CEO, + more</h3>
              <p>
                All team members were involved in brainstorming and design
                feedback at various points along the way.
              </p>
            </div>
          </div>
        </section>

        <section className="portfolio-subsection">
          <p className="label"><span>The Vision</span></p>
          <h3>
            Accelerating urban innovation: creating better ways for local
            governments to discover, test, and share innovative solutions
          </h3>
          <p className="justify">
            I joined UrbanLeap not only as their founding designer, but their
            very first employee. The founders sold me on a vision of a world
            where the innovation gap between government and the private sector
            was imperceivable.
          </p>
          <blockquote className="justify">
            They came to me with napkin sketches, and I was drawn to two key
            opportunities to apply my design skills: (1) helping government and
            the private sector work better together, and (2) removing barriers
            for government employees so that they can focus on solving some of
            the most complex, sensitive, and unique challenges around innovating
            for the public. The first step was to grow and secure funding to stay
            afloat.
          </blockquote>
        </section>

        <section className="portfolio-subsection">
          <p className="label"><span>The Progress</span></p>
          <h3>
            $3.3M in seed funding, 20+ paying customers (cities like Las Vegas
            and Tel Aviv), a team of 10+, and a ton of learnings 🎉
          </h3>
          <p className="justify">
            The path towards the vision and the product strategy shifted quite a
            bit while I was with the team, but we were learning about our
            customers and adapting to the needs of the market.
            When I started we were basically a pilot-project management tool
            focused on the unique relationship between government and
            private-sector solution providers. When I left, we were headed
            towards an operational marketplace.
          </p>
          <blockquote className="justify">
            The team grew from the founders and I to 10 people by August of
            2019. I helped them secure $3.3M in seed funding and customers like
            Sacramento, Las Vegas, Pittsburgh, and Tel Aviv by designing the
            platform from the ground up in close collaboration with the team and
            our prospective and current customers.
          </blockquote>
        </section>
      </section>

      <section className="portfolio-section">
        <h2>The Journey</h2>

        <section className="portfolio-subsection">
          <p className="label"><span>Contribution Examples</span></p>
          <h3>
            A collection of my work throughout my two-year tenure at UrbanLeap
          </h3>
          <p className="justify">
            The last two years of my product design career have been go-go-go.
            But that didn't mean go straight into Sketch or even straight into
            brainstorming. I pushed hard to empathize with and understand our
            users deeply through research. I found time to do a mix of
            {' '}<strong><em>100+ user interviews, site visits, and usability tests</em></strong>{' '}
            along the way 🧐💡🤓.
          </p>
        </section>

        <section className="portfolio-subsection">
          <div className="grid col-1-5">
            <div>
            </div>
            <div className="storyHighlight" >
              <p><strong>Early research synthesis</strong></p>
              <p className="captionLarge justify">
                I didn't always make it look this pretty (until I found Miro),
                but making sense of my research by categorizing and mapping
                insights on various types of 2x2 scales was key in making tough
                decisions.
              </p>
              <img
                className="noSpacing"
                src="/images/projects/urbanleapOverview/urbanleap_overview_prioritization.png"
                alt=""
              />
            </div>
          </div>
        </section>

        <section className="portfolio-subsection">
          <p className="justify">
            I shared my work-in-progress, early and often, and wasn't shy about
            asking for a thinking partner. I believe that good design ideas
            can come from <em>anyone</em>.
          </p>
        </section>

        <section className="portfolio-subsection">
          <div className="grid col-1-5">
            <div>
            </div>
            <div className="storyHighlight">
              <p><strong>Seeking early feedback</strong></p>
              <p className="captionLarge justify">
                From paper, to whiteboards, Balsamiq and Whimsical, I used a
                combination of methods to communicate early ideas with local and
                remote team members.
              </p>
              <img
                className="noSpacing"
                src="/images/projects/urbanleapOverview/urbanleap_overview_early-work.png"
                alt=""
              />
            </div>
          </div>
        </section>

        <section className="portfolio-subsection">
          <p className="justify">
            I learned to balance my yearning for perfectionism with the needs of
            a startup. I read
            {' '}<a href="https://jeffgothelf.com/books/#lean-ux" target="_blank" rel="noopener noreferrer">Lean UX</a>{' '}
            twice, kept
            {' '}<a href="https://abookapart.com/products/just-enough-research" target="_blank" rel="noopener noreferrer">Just Enough Research</a>{' '}
            and
            {' '}<a href="http://leanproductplaybook.com/" target="_blank" rel="noopener noreferrer">The Lean Product Playbook</a>{' '}
            close at hand, and navigated the waters of ruthless prioritization
            and scoping in order to deliver a quality product, quickly and
            incrementally.
          </p>
        </section>

        <section className="portfolio-subsection">
          <div className="grid col-1-5">
            <div>
            </div>
            <div className="storyHighlight">
              <p><strong>Project: Kanban board</strong></p>
              <p className="captionLarge justify">
                A kanban board in UrbanLeap had cards that opened up to innovation
                projects that had been continuously growing in complexity. We
                experimented (and messed up and iterated) with designs over time to
                ensure that the cards reflected the right information and cues so
                that the user always felt in control and informed.
              </p>
              <img
                className="noSpacing shadow"
                src="/images/projects/urbanleapOverview/urbanleap_overview_kanban.png"
                alt=""
              />
            </div>
          </div>
        </section>

        <section className="portfolio-subsection">
          <p className="justify">
            We lacked high volumes of activity on the platform, so I made the
            best decisions I could with the tools and data I had and relied
            heavily on the generosity of our amazing user base to provide feedback.
          </p>
        </section>

        <section className="portfolio-subsection">
          <div className="grid col-1-5">
            <div>
            </div>
            <div className="storyHighlight">
              <p><strong>Project: Program home</strong></p>
              <p className="captionLarge justify">
                A potential customer needed to communicate with cities across the
                country about projects on UrbanLeap. Solving for this need would
                also benefit some current customers. So, I switched gears mid-sprint,
                and we designed and built a new Program Home page. The contract was
                secured and the cohort successfully launched using the new Program
                Home as a rallying point.
              </p>
              <img
                className="noSpacing shadow"
                src="/images/projects/urbanleapOverview/urbanleap_overview_program-home.png"
                alt=""
              />
            </div>
          </div>
        </section>

        <section className="portfolio-subsection">
          <p className="justify">
            More often than not, feedback and results were somewhere in the
            middle—not clearly strong in one direction or another. And I learned
            the hard way that even when <em>results</em> are not decisive, <em>you</em> have to
            find a way to be decisive.
          </p>
        </section>

        <section className="portfolio-subsection">
          <div className="grid col-1-5">
            <div>
            </div>
            <div className="storyHighlight">
              <p><strong>Project: Discussion feed</strong></p>
              <p className="captionLarge justify">
                Project activity was lagging and users felt like they were missing
                out on what was going on. I redesigned the original chat feature to
                reflect something more like a project feed and was met with a mix of
                neutral and positive outcomes on increasing user activity and
                satisfaction.
              </p>
              <img
                className="noSpacing shadow"
                src="/images/projects/urbanleapOverview/urbanleap_overview_pilot-feed.png"
                alt=""
              />
            </div>
          </div>
        </section>

        <section className="portfolio-subsection">
          <p>
            We learned to celebrate our wins... 🎉
          </p>
        </section>

        <section className="portfolio-subsection">
          <div className="grid col-1-5">
            <div>
            </div>
            <div className="storyHighlight">
              <p><strong>Project: Proposal scoring</strong></p>
              <p className="captionLarge justify">
                In our MVP, a proposal submitted by a vendor could only be accepted
                or rejected. Requests came in from our customers to expand on their
                options. So I worked closely with our users and VP of Customer
                Success to research and design something that matched and even
                improved their workflow. The new scoring mechanism was deployed and
                users loved it. It became the most used and raved-about section in
                our app.
              </p>
              <img
                className="noSpacing shadow"
                src="/images/projects/urbanleapOverview/urbanleap_overview_scorecards.png"
                alt=""
              />
            </div>
          </div>
        </section>

        <section className="portfolio-subsection">
          <p>
            And I learned how we <em>should have</em> cut our losses...✂️
          </p>
        </section>

        <section className="portfolio-subsection">
          <div className="grid col-1-5">
            <div>
            </div>
            <div className="storyHighlight">
              <p><strong>Project: Dashboard</strong></p>
              <p className="captionLarge justify">
                Perhaps our most ambitious project was a dashboard for innovation
                overseers (e.g. CIOs, Department heads, etc), designed to give them
                an overview of status and trends in their innovation portfolio. This
                was a project where I learned a lot and a good lesson sank in: that
                people are terrible at predicting their future behavior and to
                beware of tempting feedback like "I'd use this <em>every day</em>."
              </p>
              <img
                className="noSpacing shadow"
                src="/images/projects/urbanleapOverview/urbanleap_overview_dashboard.png"
                alt=""
              />
            </div>
          </div>
        </section>

        <section className="portfolio-subsection">
          <p>
            And we learned that even in an early stage startup, edge cases still matter...
          </p>
        </section>

        <section className="portfolio-subsection">
          <div className="grid col-1-5">
            <div>
            </div>
            <div className="storyHighlight">
              <p><strong>Project: Responsive design</strong></p>
              <p className="captionLarge justify">
                Most of our users worked on UrbanLeap at their large desktops or
                laptops at work. So, most of the time we prioritized designing for
                1280px or wider. But as time passed and we acquired more users,
                watching mobile interactions on FullStory became more and more
                painful. We chipped away at improvements and observed mobile
                sessions lengthen in duration.
              </p>
              <img
                className="noSpacing shadow"
                src="/images/projects/urbanleapOverview/urbanleap_overview_mobile-friendly.png"
                alt=""
              />
            </div>
          </div>
        </section>

        <section className="portfolio-subsection">
          <p className="justify">
            The opportunity to tackle a variety of design problems across an
            entire product as a founding designer allowed me to fail fast,
            learn quickly, sharpen my business acumen, and understand a more
            holistic view of user-centered design in relation to a company. I
            couldn't be more thankful for the growth I gained in hard and soft
            skills along the way, and I can't wait to apply my new skills to the
            next chapter in my design career 🚀.
          </p>
        </section>
      </section>
      <h3>Check out a case study in depth:</h3>
    </section>
  </section>
);

export default UrbanLeapOverview;
