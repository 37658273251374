import React from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';
import { darkTheme } from '../../style/theme';
import rem from '../../style/rem';
import Header, { headerPropTypes } from './Header';
import RightArrow from '../RightArrow';

const ProjectContainer = styled.a`
  display: flex;
  flex-direction: column;
  align-items: space-between;
  color: ${props => props.theme.fontColor};
  background-color: ${props => (
    (props.theme.projectColors[props.colorKey]
      && props.theme.projectColors[props.colorKey].main)
    || props.theme.backgroundColor
  )};
  background: ${props => (
    (props.theme.projectColors[props.colorKey]
      && props.theme.projectColors[props.colorKey].glow)
    || props.theme.backgroundColor
  )};
  text-decoration: none;
  border-radius: 2px;
  box-shadow: 0 0 18px 0 rgba(0, 5, 8, .12), 1px 4px 8px 0 rgba(0, 5, 8, .2);

  transition: all .6s ease;

  :visited {
    color: ${props => props.theme.fontColor};
  }

  :hover {
    transform: scale(1.02);
  }
`;

const CopyContainer = styled.div`
  padding: ${rem(24)};
`;

const H4 = styled.h4`
  font-size: ${rem(28)};
  font-weight: 600;
  line-height: ${rem(36)};
  margin-bottom: ${rem(16)};
`;

const P = styled.p`
  font-size: ${rem(14)};
  color: ${props => props.theme.fontColorTransparent};
  font-style: italic;
  margin-bottom: ${rem(40)};
`;

const MockA = styled.p`
  color: ${props => props.theme.linkColor};
  fill: ${props => props.theme.linkColor};
  font-size: ${props => props.theme.fontSizeP};
  line-height: ${rem(24)};
  font-weight: 700;
  text-decoration: none;
  display: inline-flex;
  margin-bottom: 0;
  padding-bottom: 8px;
  position: relative;

  :hover .link-anim {
    margin-left: 16px;
  }
`;

const LinkAnim = styled.span`
  display: inline-flex;
  width: ${rem(14)};
  transition: margin-left 0.2s ease;
  margin-left: 10px;

  svg {
    width: 100%;
  }
`;

export const projectPropTypes = {
  title: headerPropTypes.title,
  links: headerPropTypes.links,
  headline: PropTypes.string,
  about: PropTypes.string,
  image: PropTypes.shape({
    path: PropTypes.string,
    alt: PropTypes.string,
  }),
  moreInfo: PropTypes.shape({
    text: PropTypes.string,
    path: PropTypes.string,
  }),
  colorKey: PropTypes.string,
};

const defaultProps = {
  title: '',
  links: [],
  headline: '',
  about: '',
  image: {},
  moreInfo: {},
  colorKey: '',
};

const ifPresent = (value) => {
  if (typeof value !== 'string' && typeof value !== 'object') {
    throw Error(`Expecting string or object, but got: ${typeof value}`);
  }

  if (typeof value === 'string') {
    return value.length > 0;
  }

  const keys = Object.keys(value);
  const anyValuesPresent = keys.some(key => value[key].length > 0);
  return keys.length > 0 && anyValuesPresent;
};

const Project = ({
  title,
  links,
  headline,
  about,
  image,
  moreInfo,
  colorKey,
}) => (
  <ThemeProvider theme={darkTheme}>
    <ProjectContainer href={moreInfo.path} colorKey={colorKey}>
      <CopyContainer>
        {ifPresent(title) ? <Header title={title} links={links} /> : null}
        {ifPresent(headline) ? <H4>{headline}</H4> : null}
        {ifPresent(about) ? <P>{about}</P> : null}
        {ifPresent(moreInfo)
          ? (
            <MockA>
              {moreInfo.text}
              <LinkAnim className="link-anim">
                <RightArrow />
              </LinkAnim>
            </MockA>
          )
          : null}
      </CopyContainer>
      {ifPresent(image)
        ? <img src={image.path} alt={image.alt} />
        : null}
    </ProjectContainer>
  </ThemeProvider>
);

Project.propTypes = projectPropTypes;
Project.defaultProps = defaultProps;

export default Project;
