import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  margin-right: ${props => (
    props.position < (props.columnQuantity - 1) ? props.theme.gridSpaceBetween : 0
  )};
`;

const propTypes = {
  columnQuantity: PropTypes.number.isRequired,
  position: PropTypes.number.isRequired,
  children: PropTypes.arrayOf(
    PropTypes.object,
  ),
};

const defaultProps = {
  children: [],
};

const Column = ({ position, children, columnQuantity }) => {
  const widthPercent = (100 / columnQuantity) - 2;

  const childrenWithPos = React.Children.toArray(children).map(
    child => React.cloneElement(child, { position }),
  );

  return (
    <ColumnContainer
      widthPercent={widthPercent}
      position={position}
      columnQuantity={columnQuantity}
    >
      {childrenWithPos}
    </ColumnContainer>
  );
};

Column.propTypes = propTypes;
Column.defaultProps = defaultProps;

export default Column;
