// Template:
// {
//   title: '',
//   headline: '',
//   about: '',
//   image: {
//     path: '',
//     alt: '',
//   },
//   moreInfo: {
//     text: '',
//     path: '',
//   },
//   links: [
//     {
//       type: 'live',
//       iconPath: '',
//       url: '',
//     },
//     {
//       type: 'github',
//       iconPath: '/images/icons/GitHub-Mark-Light-120px-plus.png',
//       url: '',
//     },
//   ],
// },

export default [
  {
    key: 'urbanleap-2',
    title: 'UrbanLeap',
    headline: 'When data is your cornerstone... and your affliction',
    about: 'Product/UX/UI/Visual - 2018',
    image: {
      path: '/images/projects/urbanleap2/main_urbanleap2.png',
      alt: 'Five metric types in a dialog',
    },
    moreInfo: {
      text: 'Read case study',
      path: '/urbanleap-2',
    },
    links: [],
    colorKey: 'urbanLeap2',
  },
  {
    key: 'urbanleap-1',
    title: 'UrbanLeap',
    headline: 'Empowering local governments with insights to propel innovation',
    about: 'Product/UX/UI - 2019',
    image: {
      path: '/images/projects/urbanleap1/main_urbanleap1.png',
      alt: 'Discover page. Comparing three solutions.',
    },
    moreInfo: {
      text: 'Read case study',
      path: '/urbanleap-1',
    },
    links: [],
    colorKey: 'urbanLeap1',
  },
  {
    key: 'urbanleap-3',
    title: 'UrbanLeap',
    headline: 'Reflecting on a 0 → 1 journey',
    about: 'Product/UX/UI/Visual - 2017-2019',
    image: {
      path: '/images/projects/urbanleapOverview/main_urbanleap-overview.png',
      alt: 'Screenshot of a discussion feed, a proposal evaluation and a mobile version of UrbanLeap',
    },
    moreInfo: {
      text: 'Read reflection',
      path: '/urbanleap-3',
    },
    links: [],
    colorKey: 'urbanLeap3',
  },
  {
    key: 'futureadvisor',
    title: 'FutureAdvisor',
    headline: 'Increasing productivity through improved workflows and diagnostics',
    about: 'UX/UI - 2017',
    image: {
      path: '/images/projects/futureadvisor/main_futureadvisor.png',
      alt: `
        A screenshot of the FutureAdvisor app. A page where traders will
        evaluate cases.
      `,
    },
    moreInfo: {
      text: 'Read case study',
      path: '/futureadvisor',
    },
    links: [],
    colorKey: 'futureAdvisor',
  },
  {
    key: 'greywire',
    title: 'GreyWire',
    headline: 'Analyzing user behavior & a competitor landscape',
    about: 'Research - 2017',
    image: {
      path: '/images/projects/greywire/main_greywire.png',
      alt: `
        Mobile, tablet, and desktop mockups of the GreyWire app. My Feed
        screen is shown for an employee logged in.
      `,
    },
    moreInfo: {
      text: 'Read case study',
      path: '/greywire',
    },
    links: [],
    colorKey: 'greyWire',
  },
];
