import React from 'react';

/* eslint-disable react/jsx-one-expression-per-line */

const UrbanLeap1 = () => (
  <section className="portfolio-project-page fade-in-up-staggered">
    <h2 className="subtitle">UrbanLeap</h2>
    <h1>Empowering local governments with insights to propel innovation</h1>
    <img src="/images/projects/urbanleap1/urbanleap_discover-display-mockup.png" alt="" />
    <section className="portfolio-body">
      <section className="portfolio-section">
        <p className="justify">
          UrbanLeap is an early-stage startup with a web application for
          local governments to manage innovation projects and programs.
          Their ultimate vision is an operational marketplace where
          municipal staff members can network and leverage learnings and
          public data from other municipalities.
        </p>
        <p className="justify">
          This project is a slice of our first attempt at delivering on
          that vision. I co-led the product strategy and fully lead the
          design of this new product from concept through mid-fidelity
          prototypes over the course of three months.
        </p>
        <h3>
          I&#39;m currently wrapping up high-fidelity designs, and this case
          study will be live soon!
        </h3>
      </section>
    </section>
  </section>
);

export default UrbanLeap1;
