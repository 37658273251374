import React from 'react';

/* eslint-disable react/jsx-one-expression-per-line */

const About = () => (
  <section className="fade-in-up-staggered">
    <img
      className="about-portrait"
      src="/images/justine-triola_about-pic.jpg"
      alt="Portrait of Justine"
    />
    <p className="centeredContent">
      <a className="fa-icon" href="https://www.linkedin.com/in/justine-triola/" target="_blank" rel="noopener noreferrer">
        <i className="fab fa-linkedin-in" />
      </a>
      <a className="fa-icon" href="mailto:justinetriola@outlook.com" target="_blank" rel="noopener noreferrer">
        <i className="fas fa-envelope" />
      </a>
      <a className="fa-icon" href="https://twitter.com/justinetriola" target="_blank" rel="noopener noreferrer">
        <i className="fab fa-twitter" />
      </a>
    </p>
    <section className="portfolio-body">
      <section className="portfolio-subsection">
        <h3>
          Hi! I'm Justine Triola: an end-to-end, digital product designer.
        </h3>
        <p>
          I design enjoyable and effective digital experiences for people so
          that they can focus on the things that actually matter.
        </p>
        <p className="justify">
          My formal background in Anthropology has given me a unique
          understanding of a global audience, while growing up all over the
          Midwest and working a variety of service-industry jobs has given me
          deeper empathy for many walks of life.
        </p>
        <p className="justify">
          I love design roles that strike a balance between detail-oriented
          tasks and strategic, big-picture thinking. I thrive on diverse,
          cross-functional, impact-driven teams, and I keep
          a <a href="https://sustain.wisconsin.edu/sustainability/triple-bottom-line/" target="_blank" rel="noopener noreferrer">triple bottom line</a> mindset
          at the heart of my work.
        </p>
        <p className="justify">
          Most recently, I was the first employee and founding designer
          at <a href="/urbanleap-3">UrbanLeap</a>.
          Prior to that I consulted for a variety of startups
          on <a href="/greywire">research</a>, <a href="/futureadvisor">UX/UI</a>, and
          visual design for web and mobile applications. Before that I ran
          a <a href="https://ignovadesign.com" target="_blank" rel="noopener noreferrer">web design and development studio</a> with
          a software developer for 2.5 years.
        </p>
      </section>

      <section className="portfolio-subsection">
        <h3>
          When I&#39;m not designing products, you can find me
        </h3>
        <ul>
          <li>Nerding out at garden centers and nurseries,</li>
          <li>Climbing, backpacking, or kayaking in California,</li>
          <li>Obsessing over my quirky, adopted, black cat, or</li>
          <li>Discovering new music and dorky dance moves.</li>
        </ul>
      </section>

      <section className="portfolio-subsection">
        <img
        className="showcase"
        src="/images/about_collage.jpg"
        alt="Collage of personal photos"
        />
      </section>

      <section className="fullWidthQuote">
        <p>
          “I hope that in this year to come, you make mistakes. Because if you
          are making mistakes, then you are making new things, trying new things,
          learning, living, pushing yourself, changing yourself, changing your
          world...
        </p>
        <p>
          Make new mistakes. Make glorious, amazing mistakes. Make mistakes
          nobody's ever made before.
        </p>
        <p>
          Don't freeze, don't stop, don't worry that it isn't good enough, or it
          isn't perfect, whatever it is: art, or love, or work or family or life.
          Whatever it is you're scared of doing, Do it."
        </p>
        <p>
          <span className="quoteSignature">- Neil Gaiman</span>
        </p>
      </section>
    </section>
  </section>
);

export default About;
