import React from 'react';

/* eslint-disable react/jsx-one-expression-per-line */

const GreyWire = () => (
  <section className="portfolio-project-page fade-in-up-staggered">
    <h2 className="subtitle">GreyWire</h2>
    <h1>Analyzing User Behavior & a Competitor Landscape</h1>
    <img
      src="/images/projects/greywire/greywire_display-mockup.png"
      alt="Mockup on desktop, tablet, and mobile of the final product showing the My Feed screen"
    />
    <section className="portfolio-body">
      <section className="portfolio-section">
        <section className="portfolio-subsection">
          <h3>The company</h3>
          <p className="justify">
            GreyWire was in the process of building a crowdsourcing idea
            platform with <em>high engagement through experiential learning</em> as
            a cornerstone. Their mission was to help companies get the most
            out of their employees and to help employees get the most out
            of themselves.
          </p>
          <h3>The goal</h3>
          <p className="justify">
            Our team was tasked with designing an MVP for their company to get
            off the ground with. The research goals were to (1) understand the
            market and existing products in the innovation management space
            and (2) form a story of the end-user: their needs, wants, and
            behaviors.
          </p>
          <h3>My role</h3>
          <p className="justify">
            I participated as a UX Researcher on a team with five other
            designers. We conducted market and user research, usability tests,
            and determined the user persona.
          </p>
        </section>
      </section>

      <section className="portfolio-section">
        <section className="portfolio-subsection">
          <h2>The Journey</h2>
          <p className="justify">
            Since GreyWire did not have a tangible product yet, we set out to
            explore what existing innovation management platforms were already
            doing. Our goal was to discover what was on the market and what
            each product&#39;s value proposition was.
          </p>
          <img
            src="/images/projects/greywire/greywire-competitiveresearch.png"
            alt="Six alternative products that are doing similar things"
          />
          <p className="justify">
            We had limited access to the applications themselves, but we were
            able to explore two different competing products in depth. The
            bulk of our research was done through combing marketing sites,
            forums, and analyzing videos.
          </p>
          <img
            src="/images/projects/greywire/greywire_competitive-research-table.svg"
            alt="A table that cross compares the six alternatives features, pros and cons"
          />
        </section>

        <section className="portfolio-subsection">
          <h3>
            Understanding user&#39;s challenges, motivations, and goals
            through user interviews
          </h3>
          <p className="justify">
            Using the demographic data given by the founders, we reached out
            to seven people in our network to do user interviews. Our goal was
            to get a better understanding of their needs, goals, behaviors,
            motivations, and frustrations. We focused on challenges and
            pain-points they were experiencing in the workplace around
            proposing new and innovative ideas.
          </p>
          <img
            className="showcase"
            src="/images/projects/greywire/greywire_userinterviews.svg"
            alt="Sticky notes from interviews that group insights by challenges, motivations, goals, desires, and concerns"
          />
        </section>

        <section className="portfolio-subsection">
          <h3>Developing a Persona to aid the team&#39;s future design decisions</h3>
          <img
            className="shadow"
            src="/images/projects/greywire/greywire_persona.svg"
            alt="Card with project manager persona of needs, goals, and behaviors"
          />
        </section>

        <section className="portfolio-subsection">
          <h3>Evaluating the user experience of competitor products</h3>
          <p className="justify">
            Our next steps were to do a solid round of usability testing on
            the two competitor products we had access to. Our goal in doing so
            was to avoid pitfalls from competitor products, so our objectives
            for the usability tests included:
          </p>
          <ul>
            <li>Understand a first-time user&#39;s comprehension of the tool</li>
            <li>Understand users’ behaviors and mental models relative to innovation</li>
            <li>Uncover any pain points they might experience while using app</li>
          </ul>
          <img
            className="showcase"
            src="/images/projects/greywire/greywire_usability-tests-sticknotes.jpg"
            alt="Sticky notes on whiteboard comparing insights from usability testing two competitors"
          />
        </section>

        <section className="portfolio-subsection">
          <h3>Recommendations from Our Usability Test Synthesis:</h3>
          <ul>
            <li>
              When ideas are displayed, they need to
              have <strong>relevant filters and a logical organization</strong>.
            </li>
            <li>
              Idea <strong>submission guidelines, examples, and requirements</strong> should
              be established and readily available.
            </li>
            <li>
              Expectations for what happens after submitting ideas should
              be made aware to the user.
            </li>
            <li>
              The UI should to be <strong>engaging and feel fun</strong> but should
              not sacrifice clarity.
            </li>
            <li>
              Voting data needs to be represented more
              clearly and <strong>downvotes should be avoided</strong>.
            </li>
            <li>
              The layout should be optimized for <strong>seamless browsing</strong>.
            </li>
          </ul>
        </section>

        <section className="portfolio-subsection">
          <h3>Next Steps</h3>
          <p className="justify">
            Due to obligations on another project, my role ended at the
            initial research stage, so the rest of the design team went on to
            design and iterate on a high-fidelity prototype.
          </p>
          <h5>The team&#39;s high-fidelity mock:</h5>
          <img
            className="showcase"
            src="/images/projects/greywire/greywire_mockup-isometric2.png"
            alt="Mobile mockups of the teams final designs"
          />
        </section>
      </section>
    </section>
  </section>
);

export default GreyWire;
