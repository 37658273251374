import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import projectContents from '../../../content/projects';
import ProjectLink from './ProjectLink';

const columnCount = (itemQty) => {
  if (itemQty < 5) {
    return itemQty;
  }

  return Math.min(4, Math.ceil(itemQty / 2));
};

const MoreProjectsGrid = styled.section`
  display: grid;
  /* grid-template-columns: repeat(4, 1fr); */
  grid-template-columns: repeat(${props => columnCount(props.itemQty)}, 1fr);
  grid-gap: 40px;

  @media only screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-gap: 56px;
  }
`;

const { arrayOf, string } = PropTypes;
const propTypes = {
  title: string,
  projectKeys: arrayOf(string),
  exclude: arrayOf(string),
};

const defaultProps = {
  title: 'More Portfolio Items',
  projectKeys: [],
  exclude: [],
};

const MoreProjects = ({ title, projectKeys, exclude }) => {
  const allProjectKeys = projectContents.map(projectContent => projectContent.key);
  const keysToFilter = projectKeys.length > 0 || allProjectKeys;
  const filteredProjectKeys = keysToFilter.filter(key => exclude.indexOf(key) === -1);
  const filteredProjectContents = projectContents.filter(projectContent => (
    filteredProjectKeys.indexOf(projectContent.key) !== -1
  ));

  return (
    <section>
      <h3>{title}</h3>
      <MoreProjectsGrid itemQty={filteredProjectContents.length}>
        {filteredProjectContents.map(projectContent => (
          <ProjectLink
            key={projectContent.headline}
            title={projectContent.title}
            description={projectContent.headline}
            imageUrl={projectContent.image.path}
            imageAlt={projectContent.image.alt}
            link={projectContent.moreInfo.path}
            linkText={projectContent.moreInfo.text}
            colorKey={projectContent.colorKey}
            projectCount={filteredProjectContents.length}
          />
        ))}
      </MoreProjectsGrid>
    </section>
  );
};

MoreProjects.propTypes = propTypes;
MoreProjects.defaultProps = defaultProps;

export default MoreProjects;
