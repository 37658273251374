import { connect } from 'react-redux';
import {
  fetchAuthFutureAdvisor,
} from '../../state/auth/actions';

const mapStateToProps = ({
  auth: {
    requestingAuthFutureadvisor,
    authorizedFutureadvisor,
    errorMessage,
  },
}) => ({
  requestingAuthFutureadvisor,
  authorizedFutureadvisor,
  errorMessage,
});

const mapDispatchToProps = dispatch => ({
  fetchAuthFutureAdvisor: password => dispatch(fetchAuthFutureAdvisor(password)),
});

const WithAuth = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default WithAuth;
