import React from 'react';
import PropTypes from 'prop-types';
import WithAuth from '../../containers/WithAuth';
import AuthCheck from './AuthCheck';

const {
  bool,
  func,
  string,
  element,
  arrayOf,
  oneOfType,
} = PropTypes;

const propTypes = {
  requestingAuthFutureadvisor: bool.isRequired,
  authorizedFutureadvisor: bool.isRequired,
  fetchAuthFutureAdvisor: func.isRequired,
  errorMessage: string,
  children: oneOfType([
    element,
    arrayOf(element),
  ]).isRequired,
};

const defaultProps = {
  errorMessage: null,
};

const PasswordProtected = ({
  requestingAuthFutureadvisor,
  authorizedFutureadvisor,
  fetchAuthFutureAdvisor,
  errorMessage,
  // authRoute, TODO: make a more general auth method
  children,
}) => (
  authorizedFutureadvisor
    ? children
    : (
      <AuthCheck
        checker={fetchAuthFutureAdvisor}
        requesting={requestingAuthFutureadvisor}
        errorMessage={errorMessage}
      />
    )
);

PasswordProtected.propTypes = propTypes;
PasswordProtected.defaultProps = defaultProps;

export default WithAuth(PasswordProtected);
