import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { lightTheme } from '../../style/theme';

const StyledFooter = styled.footer`
  display: flex;
  justify-content: center;
  color: ${props => props.theme.fontColor};
  background-color: ${props => props.theme.footerBackgroundColor};
  padding: 6rem 1rem;
  text-align: center;

  p {
    color: ${props => props.theme.fontColor};
  }
`;

const A = styled.a`
  color: ${props => props.theme.linkColor};

  :visited {
    color: ${props => props.theme.linkColor};
  }
`;

const FooterContent = styled.div`
  max-width: ${props => props.theme.maxWidth};
`;

const Footer = () => (
  <ThemeProvider theme={lightTheme}>
    <StyledFooter>
      <FooterContent>
        <section className="footer">
          <p>
            <A className="fa-icon" href="https://www.linkedin.com/in/justine-triola/" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-linkedin-in" />
            </A>
            <A className="fa-icon" href="mailto:justinetriola@outlook.com" target="_blank" rel="noopener noreferrer">
              <i className="fas fa-envelope" />
            </A>
            <A className="fa-icon" href="https://twitter.com/justinetriola" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-twitter" />
            </A>
          </p>

          <p className="caption-footer">Copyright © 2019 Justine Triola</p>
          <p className="caption-footer">
            Coded from scratch with help from
            {' '}
            <A href="https://dominicktriola.com" target="_blank" rel="noopener noreferrer">Dominick Triola</A>
          </p>
        </section>
      </FooterContent>
    </StyledFooter>
  </ThemeProvider>
);

export default Footer;
