import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Text from './Text';
import Project, { projectPropTypes } from './Project';
import rem from '../../style/rem';
import RightArrow from '../RightArrow';

const TileContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: ${props => props.alignItems};
  margin-bottom: ${props => props.theme.gridSpaceBetween};

  animation: fadeInUp ${props => 1 + props.index * 0.2}s;
`;

const A = styled.a`
  color: ${props => props.theme.linkColor};
  font-size: ${props => props.theme.fontSizeP};
  line-height: ${rem(24)};
  font-weight: 700;
  text-decoration: none;
  display: inline-flex;
  padding-bottom: ${rem(8)};
  margin-top: ${rem(20)};
  position: relative;

  :visited {
    color: ${props => props.theme.linkColor};
  }

  :hover .link-anim {
    margin-left: 16px;
  }
`;

const LinkAnim = styled.span`
  display: inline-flex;
  fill: ${props => props.theme.linkColor};
  width: ${rem(14)};
  transition: margin-left 0.2s ease;
  margin-left: 10px;

  svg {
    width: 100%;
  }
`;

const propTypes = {
  index: PropTypes.number.isRequired,
  text: PropTypes.string,
  project: PropTypes.shape(projectPropTypes),
  position: PropTypes.number,
};

const defaultProps = {
  text: '',
  project: {},
  position: 0,
};

const Tile = ({
  index,
  text,
  project,
  position,
}) => {
  const isProject = Object.keys(project).length > 0;
  const alignItemsIfText = position === 0 ? 'flex-end' : 'flex-start';
  const alignItems = isProject ? 'stretch' : alignItemsIfText;

  return (
    <TileContainer index={index} alignItems={alignItems}>
      {isProject
        ? (
          <Project
            title={project.title}
            links={project.links}
            headline={project.headline}
            about={project.about}
            image={project.image}
            moreInfo={project.moreInfo}
            colorKey={project.colorKey}
          />
        )
        : (
          <Text>
            {text}
            <br />
            <A href="/about">
              More about me
              <LinkAnim className="link-anim">
                <RightArrow />
              </LinkAnim>
            </A>
          </Text>
        )
      }
    </TileContainer>
  );
};

Tile.propTypes = propTypes;
Tile.defaultProps = defaultProps;

export default Tile;
