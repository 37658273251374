import PropTypes from 'prop-types';

export const childrenPropTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export const themePropTypes = {
  theme: PropTypes.shape({}).isRequired,
};
