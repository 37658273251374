import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withRouter } from 'react-router';

const MenuLink = styled.a`
  color: ${props => (props.isActive ? props.theme.activeMenuColor : props.theme.menuColor)};
  font-weight: 600;
  text-decoration: none;
  padding: 8px 0;
  margin-left: 20px;
  position: relative;

  :visited {
    color: ${props => (props.isActive ? props.theme.activeMenuColor : props.theme.menuColor)};
  }

  :after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: ${props => (props.isActive ? '100%' : 0)};
    height: 4px;
    background:
      ${props => (props.isActive ? props.theme.colorPrimary : props.theme.menuColor)};
    transition-property: width;
    transition-duration: 0.3s;
    transition-timing-function: ease-out;
  }

  :hover:after,
  :focus:after,
  :active:after {
    width: 100%;
  }
`;

export const menuItemsPropTypes = {
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
    }),
  ),
};

const locationPropTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

const propTypes = { ...menuItemsPropTypes, ...locationPropTypes };

export const menuItemsDefaultProps = {
  menuItems: [],
};

const Menu = ({ menuItems, location: { pathname } }) => (
  <div>
    {menuItems.map(
      item => (
        <MenuLink
          key={item.text}
          href={item.link}
          isActive={item.link === pathname}
        >
          {item.text}
        </MenuLink>
      ),
    )}
  </div>
);

Menu.propTypes = propTypes;
Menu.defaultProps = menuItemsDefaultProps;

export default withRouter(Menu);
