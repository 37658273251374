import React from 'react';

/* eslint-disable react/jsx-one-expression-per-line */

const UrbanLeap2 = () => (
  <section className="portfolio-project-page fade-in-up-staggered">
    <h2 className="subtitle">UrbanLeap</h2>
    <h1>When data is your cornerstone... and your affliction</h1>
    <img
      src="/images/projects/urbanleap2/urbanleap_metric-types-display.png"
      alt="Illustration of five Metric types - Growth, Savings, Proportion, and Quantity"
    />
    <section className="portfolio-body">
      <section className="portfolio-section">
        <p className="justify">
          In August of 2018, as UrbanLeap's founding designer, I lead the
          redesign of an improved Metrics-input experience from end-to-end.
          To boost the quality and quantity of metrics defined in the app by
          users, I worked closely with engineering and our customers to discover
          the most appropriate metric types and improve the usability of the
          Metrics feature.
        </p>
        <img
          className="shadow showcase"
          src="/images/projects/urbanleap2/urbanleap_metrics_mockup.png"
          alt="Screenshot of Metric dialog - form with five Metric types at the top"
        />
      </section>

      <section className="portfolio-section">
        <section className="portfolio-subsection">
          <h2>Overview</h2>
          <div className="grid col-2-1">
            <div>
              <p className="label"><span>The Company</span></p>
              <h3>UrbanLeap: An innovation-project management platform for local government</h3>
              <p className="justify">
                UrbanLeap is an early-stage startup with a web application for local
                governments to manage innovation projects and programs in collaboration with vendors. Their
                product aimed to support and encourage governments testing new
                products before procuring them. Their ultimate vision is an
                operational marketplace where municipal staff members can network
                with one another to leverage learnings and public data from other
                municipalities.
              </p>
            </div>
            <div className="grid-item-center">
              <img
                src="/images/projects/urbanleap2/urbanleap_wordmark.png"
                alt="UrbanLeap Wordmark"
              />
            </div>
          </div>
        </section>

        <section className="portfolio-subsection">
          <div className="grid col-1-1-1">
            <div>
              <p className="label"><span>The Users</span></p>
              <h3>Innovation project managers (IPMs)</h3>
              <p>
                Local government project managers in departments like Transportation, IT, Police, and Fire are the primary users. Their responsibilities range from planning to execution.
              </p>
            </div>
            <div>
              <p className="label"><span>My Role</span></p>
              <h3>Only designer;<br />end-to-end</h3>
              <p>
                I was the founding and only designer; responsible for collaborating with CTO on product, and owning research through final hi-fidelity designs.
              </p>
            </div>
            <div>
              <p className="label"><span>The Team</span></p>
              <h3>Engineer, CTO, CEO, and VP of CS</h3>
              <p>
                The CTO (co-founder) and I collaborated closely on product
                decisions, and all team members were involved in design feedback
                along the way.
              </p>
            </div>
          </div>
        </section>

        <section className="portfolio-subsection">
          <p className="label"><span>The Problem</span></p>
          <h3>
            Often, IPMs <em>weren't</em> defining project metrics with the Metrics
            feature, or what was defined <em>didn't make sense</em>.
          </h3>
          <p className="justify">
            Data is key to the company's ultimate vision of delivering
            insights to customers. So, ensuring that the quantity and quality of metrics (a.k.a. key performance indicators) recorded within project plans on the platform was important. But, the team realized that users weren't defining Metrics and when they were, they were often improperly recorded.
          </p>
          <figure>
            <img
              src="/images/projects/urbanleap2/urbanleap_problem-context.png"
              alt="Original Metric dialogs - form with three Metric types - Savings, Numeric, and Survey"
            />
            <figcaption>
              A project with a confusing metric defined and the dialog used at the time to define Metrics.
            </figcaption>
          </figure>
          <p className="justify">
            <strong>Side note:</strong> This project got me <strong><em>fired up.</em></strong>
            {' '}😍🔥☕️ Going deep into how teams measure success (and how they don't) was an enlightening and exciting personal experience. Ironically, we didn't define our own quantitative metrics
            on this project, but it's something I now strive for and love pushing
            myself and my team towards.
          </p>
        </section>

        <section className="portfolio-subsection">
          <p className="label"><span>Our Constraints</span></p>
          <h3>
            3 (partial) weeks from discovery to engineering-ready handoff
          </h3>
          <p className="justify">
            I had three weeks to take this project from a problem statement to
            engineering-ready specs, while I simultaneously conducted an
            un-related, multi-week research sprint. I worked with engineering to
            scope the project, keeping in mind that they would only have one week
            and one engineer to build.
          </p>
        </section>

        <section className="portfolio-subsection">
          <p className="label"><span>The Outcome</span></p>
          <h3>Far from knocking it out of the park, but a big leap forward</h3>
          <p className="justify">
            In order to carry out the company's ultimate vision of delivering
            insights to customers, we needed to improve on the Metrics problem
            by (1) increasing the amount of projects with defined Metrics as
            well as (2) ensuring that IPMs could define their Metrics more
            accurately.
          </p>
          <p className="justify">
            Our team would have to measure the real, quantifiable impact of the
            solution over time as our usage rates were far too low to gather
            meaningful data at the time. So, we supplemented with qualitative
            data from user feedback throughout the design process.
          </p>
          <br />
          <br />
          <div className="grid col-1-1">
            <div>
              <p>
                <strong>Goal 1</strong>
              </p>
              <p>
                More projects with defined metrics
              </p>
              <p>
                <strong>Outcome</strong>
              </p>
              <p>
                ❌
                The longer-term impact is yet to be measured, but there was no
                immediate impact. We anticipated this to a degree, however: we
                uncovered during research that defining Metrics simply wasn't part
                of the organizational culture for many IPMs yet. 😢
              </p>
            </div>
            <div>
              <p>
                <strong>Goal 2</strong>
              </p>
              <p>
                More accurately defined Metrics
              </p>
              <p>
                <strong>Outcome</strong>
              </p>
              <p>
                ✅
                Our new Metric types met the IPMs' most common needs through a
                better set of options tailored to their unique way of measuring
                outcomes. 🎯
              </p>
              <p>
                ✅
                IPMs could define Metrics more accurately with significantly less
                struggle. This was accomplished with a variety of improvements to
                the form including illustrations, copy improvement, dynamic
                examples, and field order. ✨
              </p>
            </div>
          </div>
          <p><strong>Final deliverable:</strong></p>
          <img
            className="figureSmall"
            src="/images/projects/urbanleap2/urbanleap_metric-dialog_final.gif"
            alt="Image in motion to show full dialog form that is toggling between all five metric types"
          />
        </section>
      </section>

      <section className="portfolio-section">
        <h2>The Journey</h2>

        <section className="portfolio-subsection">
          <p className="label"><span>Investigating The Why</span></p>
          <h3>
            Research revealed a variety of usability issues in addition to a
            deeper behavioral barrier
          </h3>
          <p className="justify">
            To move forward, I set out to understand why we might be seeing low
            quantities and qualities of metric inputs by leveraging interviews
            we already had lined up for an un-related research sprint.
          </p>
          <div className="grid col-1-1">
            <div>
              <p><strong>Usability testing revealed:</strong></p>
              <p className="insight">
                💡 IPMs were <strong>struggling to understand</strong> the metric
                types and how to define their metric using the dialog.
              </p>
              <p className="insight">
                💡 IPMs felt that the available metric <strong>types didn't fit
                their needs;</strong> the existing metric types were being hacked
                to fill in what we didn't support.
              </p>
              <p className="insight">
                💡 + more insights
              </p>
            </div>
            <div>
              <p><strong>User interviews revealed:</strong></p>
              <p className="insight">
                💡 Usually, <strong>IPMs aren't the ones who determine
                metrics</strong>; it's their leaders (such as department directors)
                who do.
              </p>
              <p className="insight">
                💡 <strong>Defining metrics on a project is rare</strong> for most
                IPMs. They know why they should, but they often don't because
                it's not part of their organizational culture.
              </p>
              <p className="insight">
                💡 + more insights
              </p>
            </div>
          </div>
          <br />
          <p className="justify">
            So other than the lack of organizational support and culture around
            defining metrics, <strong>the root cause of low qualities and quantities of
            metrics defined appeared to be the dialog</strong>.
          </p>
          <img
            src="/images/projects/urbanleap2/urbanleap_problem_original-dialog.png"
            alt="Original Metric dialogs - form with three Metric types - Savings, Numeric, and Survey"
          />
        </section>

        <section className="portfolio-subsection">
          <p className="label"><span>Forming a Hypothesis</span></p>
          <h3>
            If we can provide a more appropriate set of metric types and address
            the usability concerns then we'll move the needle on both goals.
          </h3>
          <div className="grid col-1-1">
            <div>
              <p>
                That fourth key insight was important to surface. Even if we
                give IPMs a perfect feature, we are unlikely to make them start
                doing something that their organization and culture doesn't
                support yet.
              </p>
            </div>
            <div>
              <p className="insight">
                💡 <strong>Defining metrics on a project is rare</strong> for most
                IPMs. They know why they should, but they often don't because
                it's not part of their organizational culture.
              </p>
            </div>
          </div>
          <p className="justify">
            But perhaps if we provide them with the right set of metrics and
            address the usability concerns, then we might pave the way for IPMs
            to begin defining metrics more often. We recognized that our decision
            would limit the increase in quantity of metrics defined that we set
            out to achieve.
          </p>
        </section>

        <section className="portfolio-subsection">
          <p className="label"><span>Defining Success</span></p>
          <h3>
            We'll measure the hypothesis using two proxy measures.
          </h3>
          <p className="justify">
            Our platform had relatively low usage and we had a short project
            timeline. So, we decided to use proxy measures to evaluate whether
            or not we were heading in the right direction:
          </p>
          <p>
            <strong>We'll know we're headed for success when...</strong>
          </p>
          <blockquote>
            A. The Metric types accommodate for the IPMs' most common metrics
          </blockquote>
          <blockquote>
            B. IPMs can define Metrics more accurately and with less of a struggle
          </blockquote>
        </section>

        <section className="portfolio-subsection">
          <p className="label"><span>Part A: New Metrics</span></p>
          <h3>
            Distilling a mess of customers' Metric variations into a set of
            common, identifiable, and actionable themes
          </h3>
          <p className="justify">
            Before diving into user flows and interaction design, I set out to
            solve for the riskiest part. Having the wrong set of metric types
            and/or not knowing how to convey them to IPMs would tank any efforts
            around usability. Not only did we need to develop a more intuitive experience for IPMs,
            but the company also needed a logical framework around metrics in
            order to leverage data and deliver insights to customers.
          </p>
          <p className="justify">
            Before attempting to solve this from scratch, I looked into how this problem
            may have been solved elsewhere.
          </p>
          <p><strong>Comparative analysis:</strong></p>
          <figure>
            <img
              src="/images/projects/urbanleap2/urbanleap_otherKPIsoftware.png"
              alt="Screenshots of other KPI software for inspiration"
            />
            <figcaption>
              Examples of KPI/metric tools. Screenshots from Klipfolio and more.
            </figcaption>
          </figure>
          <p className="justify">
            I discovered that lots of other products have similar tools, but
            everything I found was far too complex or industry-specific for our
            users' needs. Our IPMs don't measure things like funnel conversion
            rates on their innovation projects. I felt that we weren't too far
            off from a solution appropriate for our IPMs.
          </p>
          <p><strong>So I used my research insights:</strong></p>
          <div className="grid col-1-1-1">
            <div>
              <p className="insight">
                💡 IPMs were <strong>struggling to understand</strong> the metric
                types and how to define their metric using the dialog.
              </p>
            </div>
            <div>
              <p className="insight">
                💡 IPMs felt that the available metric <strong>types didn't fit
                their needs;</strong> the existing metric types were being hacked
                to fill in what we didn't support.
              </p>
            </div>
            <div>
              <p className="insight">
                💡 Usually, <strong>IPMs aren't the ones who determine
                metrics</strong>; it's their leaders (such as department directors)
                who do.
              </p>
            </div>
          </div>
          <br />
          <p>
            <strong>And I set off to scrape and aggregate existing metrics defined by IPMs:</strong>
          </p>
          <figure>
            <img
              className="showcase"
              src="/images/projects/urbanleap2/urbanleap_gathering-data.png"
              alt="Screenshot of a handful of users pilot plans on UrbanLeap"
            />
            <figcaption>
              Pulling metric examples from project plans on a handful of accounts
            </figcaption>
          </figure>
          <p className="justify">
            From there I analyzed patterns in the language IPMs used and what
            types of metrics they were defining. I eventually identified thematic
            roots of their metrics and possibilities for new names...which took
            a bit more work and time than I had anticipated! I worked back and
            forth with engineering to help refine the themes, and we passed our
            ideas by IPMs and their leaders (who decide on the metrics!).
          </p>
          <img
            className="showcase"
            src="/images/projects/urbanleap2/urbanleap_metrics-patterns.svg"
            alt="Early sketches in the background with the five new Metric types - Growth, Savings, Proportion, Quantity, and Satisfaction"
          />
          <p className="justify">
            We ran into issues around the proposed metric types and the language we used. For
            example, one originally called "Percentage" threw users off because Growth and
            Savings are percentages too. But, soon we landed on a set that we were ready to test.
          </p>
        </section>

        <section className="portfolio-subsection">
          <p className="label"><span>Part B: Improving Usability</span></p>
          <h3>
            Improving usability through more familiar language and flows
          </h3>
          <p className="justify">
            Once we felt reasonably confident with the new set Metric types,
            I set out to tackle the overall usability and experience.
          </p>
          <p>
            <strong>I used my research insights again to rethink the input experience:</strong>
          </p>
          <div className="grid col-1-1-1">
            <div>
              <p className="insight">
                💡 IPMs were <strong>struggling to understand</strong> the metric
                types and how to define their metric using the dialog.
              </p>
            </div>
            <div>
              <p className="insight">
                💡 Usually, <strong>IPMs aren't the ones who determine
                metrics</strong>; it's their leaders (such as department directors)
                who do.
              </p>
            </div>
            <div>
              <p className="insight">
                💡 <strong>Defining metrics on a project is rare</strong> for most
                IPMs. They know why they should, but they often don't because
                it's not part of their organizational culture.
              </p>
            </div>
          </div>
          <br />
          <p className="justify">
            Ideas started forming, and I started sketching, but I had to work
            closely with engineering to stay in scope. Although I brainstormed
            ideas that would sit outside of our existing dialog, I ultimately
            had to design within the existing dialog. We needed to stay
            consistent across the experience with other project plan sections
            and engineering was short on resources.
          </p>
          <figure>
            <img
              className="showcase"
              src="/images/projects/urbanleap2/urbanleap_UI-sketches.png"
              alt="Early UI sketches on paper"
            />
            <figcaption>
              A snippet of explorations and a peek at the evolution of
              the new Metrics dialog.
            </figcaption>
          </figure>
          <p className="justify">
            To sense whether we were on track with our goals or not, we put our
            new metric types, form flow, and labelling to the test with a
            clickable wireframe for usability tests.
          </p>
          <img
          src="/images/projects/urbanleap2/urbanleap_low-fi.gif"
          alt="Wireframe of new dialog with lo-fidelity illustrations for metrics"
          />
          <div className="grid col-1-1">
            <div>
              <p>
                ✅ Users had an easier time finding the right metric with the
                new types and illustrations
              </p>
              <p>
                ✅ Users followed and understood the form fields better with
                the new labelling
              </p>
            </div>
            <div>
              <p>
                ❌ Users still didn't quite navigate the flow of the form smoothly
              </p>
              <p>
                ❌ Users hesitated in grasping a couple of metric types (Ratio and Survey)
              </p>
            </div>
          </div>
        </section>

        <section className="portfolio-subsection">
          <p className="label"><span>Evaluations and iterations</span></p>
          <h3>
            Early user feedback allowed us to iterate quickly towards a better
            solution before development
          </h3>
          <p className="justify">
            Some examples of a few approaches I took throughout iterations
            based on feedback:
          </p>
          <div className="grid col-1-1">
            <div>
              <strong>Enhance with illustrations</strong>
              <img
                src="/images/projects/urbanleap2/urbanleap_metric-types-group.png"
                alt="Simple illustrations for all five Metric types"
              />
            </div>
            <div>
              <strong>Support with examples</strong>
              <img
                src="/images/projects/urbanleap2/urbanleap_metrics_dialog-examples.png"
                alt="Screenshot of dialog with examples under illustrations and in placeholder text"
              />
            </div>
          </div>
          <div className="grid col-1-1">
            <div>
              <strong>Add dynamic labels and placeholder text</strong>
              <img
                src="/images/projects/urbanleap2/urbanleap_metric-dialog_savings-growth.gif"
                alt="Example of toggling between two Metric types to see different labels and examples"
              />
            </div>
            <div>
              <strong>Move Metric Name to last field</strong>
              <img
                src="/images/projects/urbanleap2/urbanleap_metrics_MetricName.png"
                alt="Screenshot of dialog focusing on the last input field for Metric Name"
              />
            </div>
          </div>
        </section>

        <section className="portfolio-subsection">
          <p className="label"><span>Outcome Recap</span></p>
          <h3>Far from hitting out of the park, but a big leap forward</h3>
          <p className="justify">
            Our team would have to measure the real, quantifiable impact of the
            solution over time as our usage rates were far too low to gather
            meaningful data at the time. However, we measured the effectiveness
            of our new solution by testing an InVision prototype with a few more
            users before launching.
          </p>
          <br />
          <br />
          <div className="grid col-1-1">
            <div>
              <p>
                ❌ <strong>More projects with defined metrics</strong>
              </p>
              <p>
                The longer-term impact is yet to be measured, but there was no
                immediate impact. We anticipated this to a degree, however: we
                uncovered during research that defining Metrics simply wasn't part
                of the organizational culture for many IPMs yet. 😢
              </p>
              <p>
                ✅ <strong>More accurately defined Metrics</strong>
              </p>
              <p>
                Our new Metric types met the IPMs' most common needs through a
                better set of options tailored to their unique way of measuring
                outcomes. 🎯
              </p>
              <p>
                IPMs could define Metrics more accurately with significantly less
                struggle. This was accomplished with a variety of improvements to
                the form including illustrations, copy improvement, dynamic
                examples, and field order. ✨
              </p>
            </div>
            <div>
              <img
                className="noSpacing"
                src="/images/projects/urbanleap2/urbanleap_metric-dialog_final.gif"
                alt="Image in motion to show full dialog form that is toggling between all five metric types"
              />
            </div>
          </div>
        </section>

        <section className="portfolio-subsection">
          <p className="label"><span>Reflections</span></p>
          <h3>Metrics are cool. 😎 Let's practice what we preach from now on.</h3>
          <p>
            <strong>If I could have done something differently, I would have been
            more deliberate about quantitatively measuring our success.</strong>
          </p>
          <p className="justify">
            Even though we didn't have tools or high usage to measure results
            quickly and effectively, I would have taken manual baseline
            measurements such as what percentage of projects had metrics, how
            many metrics were defined on each project, and what percentage of
            metrics were recorded improperly. Then I would have measured each of
            those things as time passed and compared them to the baseline.
          </p>
          <p>
            <strong>If I had more time, I would have solved this issue further upstream.</strong>
          </p>
          <p className="justify">
            I would have loved to help solve this issue at the behavioral level
            by making the tool catered towards those IPMs who don't currently
            define metrics in their organization. We could have done some things
            like suggesting or even automating metrics based on other data in
            their project plans (and pulling examples from other accounts or a
              database) or perhaps provided a more guided experience that helps
              a user actually decide what and how to measure outcomes.
          </p>
        </section>
      </section>
    </section>
  </section>
);

export default UrbanLeap2;
