/* eslint-disable import/prefer-default-export */

export const fetchAuth = (resource, password) => (
  fetch('/authorize', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      resource,
      password,
    }),
  })
);
