import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import rem from '../../style/rem';

const TextContainer = styled.div`
  color: ${props => props.theme.fontColorMedium};
  font-size: ${rem(20)};
  line-height: ${rem(28)};
  text-align: left;
  align-items: right;
`;

const {
  arrayOf,
  oneOfType,
  string,
  element,
} = PropTypes;

const propTypes = {
  children: arrayOf(oneOfType([string, element])),
};

const defaultProps = {
  children: [],
};

const Text = ({ children }) => (
  <TextContainer>
    {children}
  </TextContainer>
);

Text.propTypes = propTypes;
Text.defaultProps = defaultProps;

export default Text;
