import React from 'react';
import TagLine from '../../components/TagLine';
import TileGrid from '../../components/TileGrid';
import Tile from '../../components/TileGrid/Tile';
import content from '../../../content';

const Home = () => (
  <React.Fragment>
    <TagLine>
      {content.tagLine}
    </TagLine>
    <TileGrid>
      {[
        <Tile key="aboutBlurb" index={0} text={content.aboutBlurb} />,
        ...content.projects.map(
          (project, index) => (
            <Tile key={project.headline} index={index + 1} project={project} />
          ),
        ),
      ]}
    </TileGrid>
  </React.Fragment>
);

export default Home;
