import React from 'react';
import styled from 'styled-components';
import RightArrowSVG from '../../images/icons/arrow-custom.svg';

// For some reason Safari and mobile browsers force the SVG height to be 150px
// if it is not explicitly set (despite the viewbox ratio).
const SVGContainer = styled.span`
  svg {
    height: 12px;
  }
`;

const RightArrow = () => (
  <SVGContainer>
    <RightArrowSVG />
  </SVGContainer>
);

export default RightArrow;
