import projects from './projects';

export default {
  logo: {
    text: 'Justine Triola',
    image: {
      path: '/images/logos/JT-emblem-solid.svg',
      alt: "Justine's emblem",
    },
  },
  menu: {
    items: [
      {
        text: 'Portfolio',
        link: '/',
      },
      {
        text: 'About',
        link: '/about',
      },
    ],
  },
  tagLine: `
    I'm Justine, a product designer based in San Francisco.
  `,
  aboutBlurb: `
    I design enjoyable and effective digital experiences for people so that
    they can focus on the things that actually matter.
  `,
  projects,
};
