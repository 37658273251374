import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { lightTheme } from './style/theme';
import content from '../content';
import GlobalStyle from './style/global';

import Header from './sections/Header';
import Body from './sections/Body';
import Footer from './sections/Footer';

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  font-family: ${props => props.theme.fontFamily};
  color: ${props => props.theme.fontColor};
  background-color: ${props => props.theme.backgroundColor};
`;

const App = () => {
  const activeTheme = lightTheme;

  return (
    <ThemeProvider theme={{ ...activeTheme }}>
      <AppContainer>
        <GlobalStyle />
        <Header
          logo={content.logo}
          menuItems={content.menu.items}
        />
        <Body />
        <Footer />
      </AppContainer>
    </ThemeProvider>
  );
};

export default App;
