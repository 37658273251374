import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import useWindowDimensions from '../../hooks/useWindowDimensions';

import Column from './Column';

const Section = styled.section`
  display: flex;
`;

const propTypes = {
  children: PropTypes.arrayOf(
    PropTypes.object,
  ),
};

const defaultProps = {
  children: [],
};

const TileGrid = ({ children }) => {
  const evens = children.filter((_, index) => index % 2 === 0);
  const odds = children.filter((_, index) => index % 2 === 1);

  const { width } = typeof window !== 'undefined'
    ? useWindowDimensions()
    : { width: 1000, height: 1000 };

  return (
    <Section>
      {width > 800
        ? (
          <React.Fragment>
            <Column position={0} columnQuantity={2}>{evens}</Column>
            <Column position={1} columnQuantity={2}>{odds}</Column>
          </React.Fragment>
        ) : (
          <Column position={0} columnQuantity={1}>{children}</Column>
        )}
    </Section>
  );
};

TileGrid.propTypes = propTypes;
TileGrid.defaultProps = defaultProps;

export default TileGrid;
