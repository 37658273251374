import React from 'react';
import styled from 'styled-components';
import { childrenPropTypes } from '../../common/propTypes';
import rem from '../../style/rem';

const P = styled.p`
  font-size: ${rem(48)};
  font-weight: 600;
  line-height: ${rem(56)};
  margin: ${rem(40)} 0 ${rem(64)} 0;
  width: 72%;

  @media only screen and (max-width: 768px) {
    font-size: ${rem(32)};
    line-height: ${rem(40)};
    width: 100%;
  }
`;

const propTypes = {
  ...childrenPropTypes.isRequired,
};

const TagLine = ({ children }) => (
  <P>{children}</P>
);

TagLine.propTypes = propTypes;

export default TagLine;
