import React from 'react';
import PasswordProtected from '../../components/PasswordProtected';
import LoadableProtected from './LoadableProtected';

/* eslint-disable react/jsx-one-expression-per-line */

const FutureAdvisor = () => (
  <section className="portfolio-project-page fade-in-up-staggered">
    <h2 className="subtitle">FutureAdvisor</h2>
    <h1>Increasing productivity through improved workflows and diagnostics</h1>
    <img
      src="/images/projects/futureadvisor/futureadvisor_portfolio-mockup.png"
      alt="Two desktop mockups with final interface screenshot"
    />
    <section className="portfolio-body">
      <section className="portfolio-section">
        <section className="portfolio-subsection">
          <h3>The company</h3>
          <p className="justify">
            FutureAdvisor&#39;s product automatically manages their
            customers&#39; investment accounts through a proprietary
            algorithm in odrer to maximize customer benefits.
          </p>
          <h3>The challenge</h3>
          <p className="justify">
            Our goal was to redesign a tool used by FutureAdvisor&#39;s
            internal personnel in order to make it more streamlined and
            intuitive.
          </p>
          <h3>My role</h3>
          <p className="justify">
            As a project lead, I worked with a team of three other UX/UI
            designers to run an end-to-end design cycle from research
            through high-fidelity prototypes.
          </p>
        </section>
        <section className="portfolio-subsection">
          <figcaption>
            To comply with my non-disclosure agreement, I have omitted
            and obfuscated proprietary and confidential information from
            this case study. The writing within this case study is my
            own and does not necessarily reflect the views of
            FutureAdvisor.
          </figcaption>
        </section>
      </section>

      <PasswordProtected>
        <LoadableProtected />
      </PasswordProtected>
    </section>
  </section>
);

export default FutureAdvisor;
