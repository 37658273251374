import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import rem from '../../style/rem';

const Img = styled.img`
  margin-right: ${rem(16)};
  width: ${rem(48)};
  height: ${rem(48)};
`;

const A = styled.a`
  display: flex;
  align-items: center;
  color: ${props => props.theme.fontColor};
  text-decoration: none;
  font-size: ${props => props.theme.fontSizeP};
  font-weight: 600;
  padding-top: ${rem(4)};

  :visited {
    color: ${props => props.theme.fontColor};
  }
`;

const Span = styled.span`
  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

export const logoPropTypes = {
  image: PropTypes.shape({
    path: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
  }).isRequired,
  text: PropTypes.string.isRequired,
};

const Logo = ({ image, text }) => (
  <A href="/">
    <Img src={image.path} alt={image.alt} />
    <Span>{text}</Span>
  </A>
);

Logo.propTypes = logoPropTypes;

export default Logo;
