import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledAuthCheck = styled.div`
  max-width: 400px;
  margin: 39px auto;
  padding: 52px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 20, 0.1);
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 20, 0.3);
  outline: none;
`;

const Submit = styled.input`
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  background-color: ${props => props.theme.colorPrimary};
  box-shadow: 0 0 10px 0 rgba(0, 0, 20, 0.1);
  border: none;
  color: white;
  text-align: center;
  font-size: ${props => props.theme.fontSizeP};
`;

const Error = styled.p`
  color: red;
`;

const propTypes = {
  checker: PropTypes.func.isRequired,
  requesting: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string,
};

const defaultProps = {
  errorMessage: null,
};

const check = (checker, password, setPassword) => {
  setPassword('');
  checker(password);
};

const AuthCheck = ({ checker, requesting, errorMessage }) => {
  const [password, setPassword] = useState('');

  return (
    <StyledAuthCheck>
      <p>
        At the request of the client, this case study has been password
        protected. Please contact me directly at
        {' '}
        <a href="mailto:justinetriola@outlook.com">justinetriola@outlook.com</a>
        {' '}
        if you&apos;re interested in reading this case study. Apologies for the
        inconvenience.
      </p>
      {/* eslint-disable-next-line */}
      <label htmlFor="password">
        Password:
        <br />
        <Input
          id="password"
          type="password"
          name="password"
          value={password}
          onChange={event => setPassword(event.target.value)}
          onKeyDown={
            event => (
              event.keyCode === 13
                ? check(checker, password, setPassword)
                : null
            )
          }
        />
      </label>
      {errorMessage ? <Error>{errorMessage}</Error> : null}
      <Submit
        type="submit"
        name="submit"
        value="Submit"
        className="button"
        disabled={requesting}
        onClick={() => check(checker, password, setPassword)}
      />
      {/* {requesting ? 'spinner...' : null} */}
    </StyledAuthCheck>
  );
};

AuthCheck.propTypes = propTypes;
AuthCheck.defaultProps = defaultProps;

export default AuthCheck;
