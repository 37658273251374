import React from 'react';
import { Route, Switch } from 'react-router';
import styled from 'styled-components';
import rem from '../../style/rem';
import Home from '../../pages/Home';
import About from '../../pages/About';
import UrbanLeap1 from '../../pages/UrbanLeap1';
import UrbanLeap2 from '../../pages/UrbanLeap2';
import UrbanLeapOverview from '../../pages/UrbanLeapOverview';
import FutureAdvisor from '../../pages/FutureAdvisor';
import GreyWire from '../../pages/GreyWire';
import MoreProjects from '../../components/MoreProjects';

const BodyContainer = styled.section`
  margin: 0 auto;
  padding: ${rem(0)} ${rem(16)} ${rem(80)} ${rem(16)};
  max-width: ${props => props.theme.maxWidth};
`;

const Body = () => (
  <BodyContainer className="fade-in-up">
    <Switch>
      <Route path="/urbanleap-1">
        <UrbanLeap1 />
        <MoreProjects exclude={['urbanleap-1']} />
      </Route>
      <Route path="/urbanleap-2">
        <UrbanLeap2 />
        <MoreProjects exclude={['urbanleap-2']} />
      </Route>
      <Route path="/urbanleap-3">
        <UrbanLeapOverview />
        <MoreProjects exclude={['urbanleap-3']} />
      </Route>
      <Route path="/futureadvisor">
        <FutureAdvisor />
        <MoreProjects exclude={['futureadvisor']} />
      </Route>
      <Route path="/greywire">
        <GreyWire />
        <MoreProjects exclude={['greywire']} />
      </Route>
      <Route path="/about">
        <About />
        <MoreProjects title="Portfolio Items" />
      </Route>
      <Route path="/">
        <Home />
      </Route>
    </Switch>
  </BodyContainer>
);

export default Body;
